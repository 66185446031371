import React, { useRef } from 'react'
import "../Join/Join.css"
import emailjs from '@emailjs/browser'

const Join = () => {
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_itx3n6c', 'template_665gt7v', form.current, 'dAAZ0FNeXF5vNiaG6')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
    };
    
    return (
        <div className='Join' id = "join-us">
            <div className="left-j">
                <hr />
                <div>
                    <span className='stroke-text'>ready to</span>
                    <span>level up</span>
                </div>
                <div>
                    <span>your body</span>
                    <span className='stroke-text'>with us?</span>
                </div>
            </div>
            <div className="right-j">
                <form ref={form} onSubmit={sendEmail} className="email-container">
                    <input type="email" name="user_email" placeholder='Enter your email address' />
                    <button className='btn btn-join'>Join Now</button>
                </form>
            </div>
        </div>
    )
}

export default Join;